.main-content {
  background-image: url("https://merchant-pricing-solutions.s3.amazonaws.com/dashboard-bg.jpg");
  background-repeat: no-repeat;

  background-size: cover;
  /* background-color: blue;
    */
}
/* .table.table-hover tbody tr:hover {
  background-color: #5366d1;
  color: white !important;
} */
