#loginformpage .view {
  background-image: url("https://mdbootstrap.com/img/Photos/Others/images/89.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 82vh;
}

#loginformpage h6 {
  line-height: 1.7;
}

#loginformpage .form-check {
  margin-bottom: 0 !important;
}

.payment-list__item {
  margin: 5px;
}
