#registerformpage .view {
  background-image: url("https://mdbootstrap.com/img/Photos/Others/images/89.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 96vh;
}

#registerformpage h6 {
  line-height: 1.7;
}

#registerformpage .form-check {
  margin-bottom: 0 !important;
}

.token {
  color: red;
}

.mcc {
  color: green;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.invalid-feedback2 {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.customSelect {
  margin-bottom: -10px;
}

.select-wrapper > label.mdb-main-label {
  color: black !important;
}

.pills-peach-gradient .nav-item .nav-link.active {
  background: linear-gradient(40deg, #ffd86f, #fc6262);
}
.pills-blue-gradient .nav-item .nav-link.active {
  background: linear-gradient(40deg, #45cafc, #303f9f);
}
.pills-purple-gradient .nav-item .nav-link.active {
  background: linear-gradient(40deg, #ff6ec4, #7873f5);
}
.pills-aqua-gradient .nav-item .nav-link.active {
  background: linear-gradient(40deg, #2096ff, #05ffa3);
}

.section-preview {
  border: 1px solid #e0e0e0;
  padding: 15px;
}

.radioButtonAlignments {
  padding-left: 50px;
}


.card.card-cascade .view.view-cascade.gradient-card-header {
  padding:7px 1rem !important;
}
