/* .card {
    min-height: 400px !important;
  } */

@media screen and (min-width: 1200px) {
  .dba {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1199px) {
  .dba {
    margin-left: 20px;
    margin-right: 20px;
  }
}

/* .card {
    min-height: 400px !important;
  } */
