.homepage-container {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.intro-title {
  font-size: 64px;
}

.intro-subtitle {
  font-size: 24px;
}

.icons {
  background: white;
}

.icon-area .circle-icon i {
  box-shadow: 0 8px 19px rgba(91, 154, 251, 0.41);
  width: 100px;
  height: 100px;
  line-height: 1.4;
  padding: 1.8rem;
  font-size: 2rem;
  border-radius: 50%;
  background: #fff;
}
.icon-area .circle-icon i:hover {
  background: #ebeef9;
}

.btn.pink-gradient,
.btn.pink-gradient:active,
.btn.pink-gradient:focus {
  background: linear-gradient(40deg, #ff5858, #ee4392);
}

html {
  scroll-behavior: smooth;
}
