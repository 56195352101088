#registerformpage .view {
  background-image: url("https://mdbootstrap.com/img/Photos/Others/images/89.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 96vh;
}

#registerformpage h6 {
  line-height: 1.7;
}

#registerformpage .form-check {
  margin-bottom: 0 !important;
}

.token {
  color: red;
}

.mcc {
  color: green;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.invalid-feedback2 {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.customSelect {
  margin-bottom: -10px;
}

.main-content {
  /* background-image: url("/dashboard-bg.jpg"); */
  background-repeat: no-repeat;

  background-size: cover;
  /* background-color: blue;
        */
}
